import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/goodsDetail',
    name: 'goodsDetail',
    component: () => import('../views/goodsDetail/index.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('../views/downloadApp/index.vue')
  },
  {
    path: '/packageDetail',
    name: 'packageDetail',
    component: () => import('../views/packageDetail')
  },
  {
    path: '/couponDetail',
    name: 'couponDetail',
    component: () => import('../views/couponDetail')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
