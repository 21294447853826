import { createApp } from 'vue'
import 'lib-flexible'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/flex.css";
import "./assets/css/common.css"

import Vant from 'vant';
import '../node_modules/vant/lib/index.css';
createApp(App).use(store).use(router).use(Vant).mount('#app')
